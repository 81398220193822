import React from "react"
import { Link } from "gatsby"

export default function footer() { 
    return(
    <>
    {/*<!--Footer-->*/}
    <footer class="bg-white shadow">
      <div class="container mx-auto">
        <div class="w-full flex sm:flex-row flex-col justify-between py-2">
          {/*To-Do: in der mobilen Ansicht sollen sich die Elemente nicht sofort stapeln*/}
          <div class="flex-auto text-black">
            <a class="no-underline hover:no-underline hover:text-black font-bold text-gray-800 lg:text-2xl" href="/">
              Pimmelband
            </a>
          </div>
          
          <div class="flex-auto">
            
          </div>

          <div class="flex-auto">
            <Link to="/agb/#" target="_self" class="no-underline hover:underline hover:text-gray-500 text-gray-500 text-xl">AGB</Link>
          </div>

          <div class="flex-auto">
            <Link to="/datenschutz/#" target="_self" class="no-underline hover:underline hover:text-gray-500 text-gray-500 text-xl">Datenschutz</Link>
          </div>

          <div class="flex-auto">
            <Link to="/impressum/#" target="_self" class="no-underline hover:underline hover:text-gray-500 text-gray-500 text-xl">Impressum</Link>
          </div>

        </div>
        <div class="w-full flex bg-white text-black text-sm">
          &copy; &nbsp; <a class="inline-block no-underline hover:text-black hover:underline text-black" href="https://www.stritago.de" rel="nofollow"> stritago.de </a>
        </div>
      </div>
    </footer>
    </>
    )
}