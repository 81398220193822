import React, { useState } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from "../components/modal"

export default function Navbar(){ 

/**Für das Popup */
const [isOpen, setIsOpen] = useState(false)

return(
    <>
    <nav id="header" class="w-full z-30 top-0 py-1">
        <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-3">
            <div class="hidden md:flex md:items-center md:w-auto w-full order-1 md:order-1" id="menu">
                <nav>
                    <ul class="md:flex items-center justify-between text-base text-gray-700 pt-4 md:pt-0">
                        <li><a class="inline-block no-underline hover:text-black hover:underline py-2 text-black" href="/">Shop</a></li>
                        <li><a class="inline-block no-underline hover:text-black hover:underline py-2 px-4 text-black" href="/#" onClick={() => setIsOpen(true)}>About</a></li>
                    </ul>
                </nav>
            </div>

            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <p class="font-semibold text-2xl pt-4 text-center">Über unseren Shop</p>
                <p class="text-center">Ihr wolltet schon länger eure Retouren oder Paketsendungen mit einem farbenfrohen Paketband aufwerten? Dann seid Ihr bei uns an der richtigen Stelle!</p>
                <p class="text-center">Mit unserem Shop möchten wir euch nicht nur unser Produkt "Pimmelband" mit eigenem Design anbieten, sondern auch innovativen Technologien in der Softwareentwicklung demonstrieren. Da es sich derzeit noch um ein erstes Pilotprojekt handelt, kann es teilweise zu Lieferzeiten von bis zu zwei Wochen kommen. Dennoch bemühen wir uns um eine zeitnahe Versendung. Derzeit ist Pimmelband nur in Deutschland erhältlich und wird auch nur innerhalb von Deutschland versendet.</p>
            </Modal>

            <div class="order-2 md:order-2">
                <h1 class="flex items-center tracking-wide no-underline hover:no-underline hover:text-black font-bold text-gray-800 text-xl " href="/shop">
                     {/* Icon Shopping Bag */}
                        &nbsp; Pimmelband
                </h1>
            </div>

            <div class="order-3 md:order-3 flex items-center" id="nav-content">
                <a class="pl-3 inline-block no-underline hover:text-black px-4" href="#/cart">
                     {/* Shopping Bag */}
                </a>
                <a class="inline-block no-underline hover:text-black hover:underline" href="#/cart" target="_self">
                   <div class="snipcart-summary text-black">
                        Items: <span class="snipcart-items-count"></span>
                    </div>
                    <div class="snipcart-summary text-black">
                        Total: <span class="snipcart-total-price"></span>
                    </div> 
                </a>
            </div>
        </div>
    </nav>
</>
)
}