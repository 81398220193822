import * as React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import {Helmet} from 'react-helmet'


const Layout = ({ children }) => (
<>
<head>
<Helmet>
<meta name="google-site-verification" content="6lDFOAelnyUTO9EdINuA0g7Rb9yynkOF7saU36DWnIs" />
</Helmet>
<script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="a5f059ef-39e0-4ee0-9653-90608b018f61" data-blockingmode="auto" type="text/javascript"></script>
</head>
<body>
<div class="leading-normal tracking-normal gradient min-h-screen">

        <Navbar></Navbar>  
       
 
       
        {children}
        

        <Footer></Footer> 
        
        
</div> 

</body>   
</>
);

export default Layout